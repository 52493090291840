import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';

@Injectable({
    providedIn: 'root'
})
export class userRightsService {

    public userRights: string[] = [];
    public videoCategories: string[] = [];
  
    constructor() {
      this.loadUserRightsFromToken();
    }
  
    // JWT aus dem Local Storage laden und dekodieren
    loadUserRightsFromToken(): void {
      const token = localStorage.getItem('token'); // Annahme: Das JWT wird im Local Storage gespeichert
      if (token) {
        const decodedToken: any = jwtDecode(token);
        this.userRights = decodedToken.userRights || [];
        this.videoCategories = decodedToken.videoCategories || []; 
      }
    }
  
    // Prüfen, ob der Benutzer ein bestimmtes Recht hat
    hasRight(requiredRight: string): boolean {
      return this.userRights.includes('isSuperAdmin') || this.userRights.includes(requiredRight);
    }
  }
